import {handleActions} from 'redux-actions'
import createSelector from '../../lib/create-selector'
import {SUBMIT_APP_URLS} from '../../store/acquisition-actions'
import {
  ADD_APP_URL,
  REMOVE_APP_URL,
  RESET_APP_SUBMISSION,
  RESET_STAGED_URL,
  UPDATE_STAGED_URL,
} from './app-url-submission-actions'
import {urlSubmissionErrorStatus} from './app-url-submission-states'

export const initialState = {
  message: null,
  urlSubmission: {
    isFetching: false,
    isError: false,
    urls: [],
    status: [],
    stagedUrl: {
      value: '',
      error: null,
    },
  },
}

const reducer = handleActions(
  {
    [RESET_APP_SUBMISSION.ACTION]: () => initialState,

    [UPDATE_STAGED_URL.ACTION]: (state, {payload}) =>
      _.merge({}, state, {
        urlSubmission: {
          stagedUrl: {
            value: payload.value,
            error: payload.error,
          },
        },
      }),

    [RESET_STAGED_URL.ACTION]: state =>
      _.merge({}, state, {
        urlSubmission: {
          stagedUrl: {
            value: '',
            error: null,
          },
        },
      }),

    [ADD_APP_URL.ACTION]: (state, {payload}) =>
      _.mergeWith(
        {},
        state,
        {
          urlSubmission: {
            urls: [payload],
          },
        },
        (objValue, srcValue) =>
          _.isArray(objValue) ? _.concat(objValue, srcValue) : undefined
      ),

    [REMOVE_APP_URL.ACTION]: (state, {payload}) =>
      _.mergeWith(
        {},
        state,
        {
          urlSubmission: {
            urls: _.reject(state.urlSubmission.urls, payload),
          },
        },
        (objValue, srcValue) => (_.isArray(objValue) ? srcValue : undefined)
      ),

    [SUBMIT_APP_URLS.REQUEST]: state =>
      _.merge({}, state, {
        urlSubmission: {
          isFetching: true,
          isError: false,
        },
      }),

    [SUBMIT_APP_URLS.SUCCESS]: (state, {payload}) =>
      _.merge({}, state, {
        message: I18n.t('apps.submission.url.success', {
          count: _.size(state.urlSubmission.urls),
        }),
        urlSubmission: {
          isFetching: false,
          isError: false,
          status: _.map(payload, ({status, url}) => ({
            isError: _.includes(urlSubmissionErrorStatus, status),
            status,
            url,
          })),
        },
      }),

    [SUBMIT_APP_URLS.ERROR]: state =>
      _.merge({}, state, {
        message: I18n.t('apps.submission.url.error', {
          count: _.size(state.urlSubmission.urls),
        }),
        urlSubmission: {
          isFetching: false,
          isError: true,
        },
      }),
  },
  initialState
)

export const getMessage = state => state.apps.appSubmission.message

export const getFetchState = state =>
  _.pick(state.apps.appSubmission.urlSubmission, ['isFetching', 'isError'])

export const getSubmissionUrls = state =>
  state.apps.appSubmission.urlSubmission.urls

export const getStagedUrl = state =>
  state.apps.appSubmission.urlSubmission.stagedUrl

export const getUrlSubmissionStatus = state =>
  state.apps.appSubmission.urlSubmission.status

export const createGetRawSubmissionUrls = () =>
  createSelector(getSubmissionUrls, urls => _.map(urls, ({value}) => value))

export default reducer
