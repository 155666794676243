/* eslint-disable no-unused-expressions */
import moment from 'moment'
import {isNetworkError} from '@lookout/request'
import {resolveLocaleAndLang} from '@lookout/i18n'
import appConfig from '../../config/app-config.yml'
import initSentry from '../services/sentry-helper'
import {initMixpanel} from '../services/mixpanel-helper'
import initWootric from '../services/wootric-helper'
import {borderPatrolHeaders} from '../services/request'
import {readConfig, readCache} from '../services/config-service'
import {assumeRole, createEntLoginEvent} from '../services/ents-service'
import {
  getEnt,
  getFeatures,
  setCachedTenancy,
  cachedTenancy,
  isTenancyCached,
  defaultEntGuid,
  getTenancyByEntGuid,
} from '../services/tenancy-helper'
import {merge, resolveC11nConfig, featureOn, can} from './utils'
import {isLoggedEnt, saveLoggedEnt} from './session'

export const hideAppLoadingProgress = () => {
  const pulse = global.document.querySelector('#root > .progress-pulse')
  if (pulse?.parentNode) pulse.parentNode.removeChild(pulse)
}

/*
 * This is the very first AJAX call that application does.
 * Loading config and cache hash values from l4e micro-service to
 * properly setup application before starting.
 */
export const loadConfiguration = async ({options} = {}) => {
  try {
    const [config, cache] = await Promise.all([
      readConfig({options}),
      readCache({options}),
    ])
    lkt.config = {...lkt.config, ...config} // extending possible existing config set by OIC parent app
    lkt.cache = cache
  } catch (error) {
    if (isNetworkError(error))
      // eslint-disable-next-line no-console
      console?.error(
        'Failed to initialize application. The app config could not be loaded.'
      )
    throw error
  }
}

const importLocale = async ({locale, language}) => {
  let translations
  let i18nLocale
  let momentLocale
  try {
    // trying to load full form locale with country (like `en-US`)
    translations = await import(`../../config/locales/console/${locale}.yml`)
    i18nLocale = locale
  } catch {
    // if didn't work let's try with just language (like `en`)
    translations = await import(`../../config/locales/console/${language}.yml`)
    i18nLocale = language
  }
  try {
    await import(`moment/locale/${locale.toLowerCase()}`)
    momentLocale = locale.toLowerCase()
  } catch {
    await import(`moment/locale/${language}`)
    momentLocale = language
  }
  return {i18nLocale, momentLocale, translations: translations.default}
}

export const loadTranslations = async () => {
  // HACK: LOOKOUT_I18N_LOCALE is for unit or integration testing (like Cypress), or other edge case debugging scenarios
  const {locale, language} = resolveLocaleAndLang(global.LOOKOUT_I18N_LOCALE)
  if (language !== 'en') {
    try {
      const {i18nLocale, momentLocale, translations} = await importLocale({
        locale,
        language,
      })
      I18n.locale = i18nLocale
      I18n.translations = _.merge({}, I18n.translations, translations)
      moment.locale(momentLocale)
    } catch {
      I18n.locale = 'en'
      moment.locale('en')
      // eslint-disable-next-line no-console
      console?.error(
        `Failed to load "${locale}" locale. Falling back to English.`
      )
    }
  }
}

const importPartnerConfig = async filename => {
  try {
    return await import(`../../config/partners/${filename}.yml`)
    // eslint-disable-next-line no-empty
  } catch {}
}

export const loadCustomizations = async (ent = getEnt()) => {
  let partnerCustomizations
  const commercialPartnerName = _.toLower(ent.commercial_partner_name)
  if (commercialPartnerName) {
    let filename = commercialPartnerName

    let partnerConfig = await importPartnerConfig(filename)

    if (!partnerConfig) {
      // Aka wildcard approach, trying to load by namespace if `_` is present. Example: `crowdstrike_sol` will point to `crowdstrike`
      filename = _.head(_.split(filename, '_'))
      partnerConfig = await importPartnerConfig(filename)
    }

    if (partnerConfig) {
      partnerCustomizations = resolveC11nConfig(partnerConfig[filename])
    } else {
      // eslint-disable-next-line no-console
      console?.error(
        `Failed to load "${commercialPartnerName}" partner customizations.`
      )
    }
  }
  return merge(
    {},
    resolveC11nConfig(appConfig.lookout, ent.features),
    partnerCustomizations
  )
}

export class TenancyError extends Error {}

export class TenancyNotAvailableError extends TenancyError {
  constructor({targetEntGuid}) {
    super(I18n.t('tenancy.errors.not_available', {ent_guid: targetEntGuid}))
    this.name = 'TenancyNotAvailableError'
  }
}

export class TenancyAssumeRoleError extends TenancyError {
  constructor() {
    super(I18n.t('error.mfa_setup'))
    this.name = 'TenancyAssumeRoleError'
  }
}

export class TenancyLoggingError extends TenancyError {
  constructor() {
    super(I18n.t('error.notification'))
    this.name = 'TenancyLoggingError'
  }
}

const isLicenseAcceptanceRequired = tenancy =>
  getEnt(tenancy).license_needs_acceptance && can('update', 'ents', tenancy)

const logAndCheckTenancy = async ({
  targetEntGuid = defaultEntGuid(),
  showSystemUseNotice,
  showLicenseAcceptance,
  ...args
} = {}) => {
  const tenancy = getTenancyByEntGuid(targetEntGuid)
  if (!tenancy) {
    if (targetEntGuid) throw new TenancyNotAvailableError({targetEntGuid})
    else return
  }

  const features = getFeatures(tenancy) || {}

  const serviceArgs = {
    ...args,
    tenancy,
    // BP headers are based on a singleton tenancy value, which is not relevant in this case of entering into another tenancy
    skipBorderPatrolHeaders: true,
    headers:
      // explicit pass of BP headers because current tenancy is not cached (put into singleton)
      features.l4e_rollout_tenancy_guid_header &&
      borderPatrolHeaders({entGuid: targetEntGuid}),
  }

  try {
    // if MFA is on in current or target ent
    if (
      featureOn('l4e_rollout_mfa_support') ||
      features.l4e_rollout_mfa_support
    )
      await assumeRole(serviceArgs)
  } catch (error) {
    if (isNetworkError(error)) throw new TenancyAssumeRoleError()
    else throw error
  }

  if (!isLoggedEnt(targetEntGuid)) {
    const systemNoticeAccepted = await (features.l4e_fedramp
      ? showSystemUseNotice()
      : Promise.resolve())
    try {
      await createEntLoginEvent({
        accept: systemNoticeAccepted,
        ...serviceArgs,
      })
    } catch (error) {
      if (isNetworkError(error)) throw new TenancyLoggingError()
      else throw error
    }

    if (systemNoticeAccepted === false) return

    const licenseAccepted = await (isLicenseAcceptanceRequired(tenancy)
      ? showLicenseAcceptance({tenancy})
      : Promise.resolve())

    if (licenseAccepted === false) return

    saveLoggedEnt(targetEntGuid)
  }

  return tenancy
}

export const initTenancyEnvironment = async ({targetEntGuid, ...args}) => {
  if (isTenancyCached(targetEntGuid)) return cachedTenancy()
  let tenancy
  try {
    tenancy = await logAndCheckTenancy({targetEntGuid, ...args})
  } finally {
    setCachedTenancy(tenancy)
  }
  if (cachedTenancy()) {
    lkt.customizations = await loadCustomizations()
    initSentry()
    initMixpanel()
    initWootric()
  }
  return tenancy
}

/*
 * Logs a funny green message into browser console
 * that "Everything is Okay" after application started.
 */
export const logEverythingIsOkay = () =>
  // eslint-disable-next-line no-console
  console?.log?.(
    `%c
    .      ▁▂▃▄▅▆▆▇▇▇▇▇▆▆▅▄▃▂▁
    .  ▁▄▆████████▀▀▀▀▀████████▆▄▁
    . ▟███▀▀▔▔             ▔▔▀▀███▙
    . ███                       ███  Everything
    . ▐██▏    ▁▃▅▆▆▇▇▇▆▆▅▃▁    ▕██▌
    . ▕██▙▂▄▇█████▀▀▀▀▀█████▇▄▁▟██▏
    .  ▜████▀▀▔▔         ▔▔▀▀████▛
    .   ▜█▙                   ▟█▛       Is
    .    ▜█▙    ▁▄▅▆▆▆▅▄▁    ▟█▛
    .     ▜██▄▆███████████▆▄██▛
    .      ▜████▀▔     ▔▀████▛
    .       ▔███▃       ▃███▔          Okay.
    .         ▀███▄▁ ▁▄███▀
    .           ▀▀█████▀▀
    .              ▔▀▔
    `,
    'color: #3DB249;'
  )
